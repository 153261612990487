import React from "react"
import { Link } from "gatsby"
import { Box, Heading, Text } from "theme-ui"

// import app components
import BackLogo from "../icons/bearclaw.svg"
import Logo from "../icons/polar-header.svg"
import Edges from "../components/Edges"

const FourOhFor = () => {
  return (
    <Box
      sx={{
        bg: "blueModuleBg",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        p: "5vw",
        height: "100vh",
        width: "100%",
        textAlign: "center",
      }}
    >
      <Heading variant="styles.heroHeading" sx={{ mb: "50px", zIndex: 2 }}>
        404
      </Heading>
      <Text as="p" variant="styles.heroText" sx={{ mb: "50px", zIndex: 2 }}>
        We're sorry, we can't find the page you're looking for.
      </Text>
      <Link to="/" style={{ zIndex: 2 }}>
        <Box
          variant="buttons.primary"
          sx={{
            display: "flex",
            variant: "buttons.primary",
            position: "relative",
            cursor: "pointer",
            textAlign: "center",
            px: ["20px", "35px"],
            py: "8px",
            borderRadius: "40px",
          }}
        >
          Return Home
        </Box>
      </Link>
      <Link to="/">
        <Box
          sx={{
            position: "absolute",
            left: "10%",
            top: 0,
            zIndex: 2,
            display: "flex",
            bg: "white",
            height: 130,
            width: 135,
            boxShadow: "0px 3px 6px #00000029",
            alignItems: "center",
            justifyContent: "center",
            svg: {
              pt: 25,
              my: "auto",
            },
          }}
        >
          <Logo />
        </Box>
      </Link>
      <Box
        sx={{
          right: 0,
          position: "absolute",
          bottom: 0,
          zIndex: 1,
          opacity: 0.7,
        }}
      >
        <BackLogo />
      </Box>
    </Box>
  )
}

export default FourOhFor
